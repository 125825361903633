<template>
  <div>
    <div class="header">
      <div class="header-header">
        <img src="../../assets/img/qianliang/auth/riLine-error-warning-line@1x.png">
        <div>温馨提示</div>
      </div>
      <div class="header-body">系统未检测到你加入任何知鸟公开课的微信群，暂时还不能参与签到活动。请先联系你的推荐人，让他先把你拉进知鸟公开课的群中，然后再进入本网页参与活动。如果联系不到你的推荐人，请扫码联系客服（添加时注明来意）</div>
    </div>
    <van-cell-group inset class="qianliang-card">
      <div class="qianliang-card-header">
<!--        <img src="../../assets/img/qianliang/antOutline-team@1x.png" class="qianliang-card-icon">-->
<!--        <div class="qianliang-card-title">知鸟公开课读者群</div>-->
      </div>
      <div class="qianliang-card-body" style="text-align: center;padding-top: 10px;">
<!--          <img src="../../assets/img/qianliang/auth/1669040420170.jpg" style="width: 35%;">-->
        <img v-if="initSuccess" :src="qrCodeUrl" style="width: 50%;">
        <div style="color: #000;margin: 20px 0 15px">扫码联系客服，添加时注明来意</div>
      </div>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: "auth",
    data(){
      return {
          qrCodeUrl: '',
          initSuccess: false,
      }
    },
    mounted() {
      this.initSuccess = false
      this.post('/member/queryChatQrcode').then(result=>{
          console.log(result.data.qrCodeUrl)
          this.qrCodeUrl = result.data.qrCodeUrl
          this.initSuccess = true
      })
    },
}
</script>

<style scoped>
.header{
  width: 100%;
  line-height: 20px;
  border-radius: 0px 0px 10px 10px;
  background-color: #FEC919;
  color: #fff;
  padding-bottom: 10px;
}
.header-header{
  padding-left: 20px;
  padding-top: 5px;
  font-size: 14px;
  text-align: left;
  font-family: PingFangSC-regular;
  display: flex;
  align-items: center;
}
.header-header img{
  height: 16px;
  width: 16px;
  margin-right: 3px;
}
.header-body{
  padding-left: 31px;
  padding-right: 19px;
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-regular;
}
</style>
